@import '../../assets/styles/media-queries.scss';

.LinksWidget {
  .sidebar-links {
    list-style: none;
    margin: 0;
    padding: 0;
    .sidebar-link {
      border-bottom: 1px solid #ddd;
      padding: 7.5px 0; } } }
