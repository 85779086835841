@import '../assets/styles/media-queries.scss';

div#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .screen-reader-text {
    display: none; }

  div.page {
    padding-top: 2rem;
    @include gt-xs {
      padding-top: 4rem; }
    .page-header {
      padding-bottom: 2em;
      width: 100%;
      .page-title {
        color: #222;
        font-size: 14px;
        font-size: 0.875rem;
        font-weight: 800;
        letter-spacing: 0.14em;
        text-transform: uppercase; } } }

  article.entry {
    .entry-header {
      margin: 0 0 1.5rem;
      .entry-meta {
        color: #767676;
        font-size: .7rem;
        font-weight: 800;
        letter-spacing: 0.15rem;
        text-transform: uppercase;
        a {
          color: #767676;
          &::before {
            background-color: #767676; } }

        time.updated:not(.published) {
          display: none; } }
      .entry-title {
        line-height: 1.4;
        font-size: 1.65rem;
        font-weight: 300;
        margin-top: 0;
        margin-bottom: 0.25rem;
        margin-left: -1px; } } } }
