@import '../assets/styles/media-queries.scss';

.TopBar {
  background: #fafafa;
  display: flex;
  flex-direction: column;
  .branding {
    padding: 3rem 0;
    line-height: 1.5;
    .site-title {
      font-size: 2.25rem;
      font-weight: 800;
      letter-spacing: 2.5px;
      line-height: 1.25;
      margin: 0;
      padding: 0;
      text-transform: uppercase;
      a {
        color: #222; } }
    .site-description {
      color: #666;
      font-size: 1rem;
      margin: 0;
      padding: 0; } }

  /// Navigation
  .navigation {
    background: #fff;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    font-size: 1rem;
    line-height: 1;
    @include gt-sm {
      font-size: .875rem; }

    .toggle-menu {
      display: flex;
      flex-direction: row;
      justify-content: center;
      @include gt-sm {
        display: none; }
      button {
        background-color: transparent;
        border: 0;
        box-shadow: none;
        color: #222;
        font-size: .875rem;
        font-weight: 800;
        line-height: 1.5;
        margin: 2px auto;
        padding: 1em;
        text-shadow: none;
        &:hover {
          background-color: #767676; } } }

    /// Custom Paper Style
    .Paper {
      display: none;
      padding-left: 0;
      padding-right: 0;
      @include gt-sm {
        display: block;
        padding-left: 1.75rem;
        padding-right: 1.75rem; } }

    &.open {
      .Paper {
        @include lt-md {
          display: block; } }
      .toggle-menu {
        border-bottom: 1px solid #eee; } }

    /// Navigation Menu
    nav.menu {
      margin-left: 0;
      margin-right: 0;
      ul {
        border: none;
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: .75rem 2rem;
        @include gt-xs {
          padding: .75rem 3rem; }
        @include gt-sm {
          flex-direction: row;
          padding: .75rem 0; }
        li {
          border-bottom: 1px solid #eee;
          display: block;
          list-style: none;
          @include gt-sm {
            border: none;
            padding: 0 1.25rem; }
          &:last-child {
            border-bottom: none; }
          a {
            display: block;
            font-weight: 600;
            padding: 1rem 0; }
          &.active {
            a:before {
              transform: scaleX(1); } } } } } } }
