@import '../assets/styles/media-queries.scss';

.Paper {
  margin-left: auto;
  margin-right: auto;
  max-width: 1000px;
  padding-left: 2rem;
  padding-right: 2rem;
  @include gt-xs {
    padding-left: 3rem;
    padding-right: 3rem; }

  &.with-sidebar {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; } }
