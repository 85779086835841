@import '../../assets/styles/media-queries.scss';

.SearchBoxWidget {
  .search-form {
    position: relative;
    display: flex;
    flex-direction: row;
    label.search-label {}
    input.search-field {
      border: 1px solid #bbb;
      border-radius: 4px;
      flex: 1;
      padding: 15px;
      outline-offset: 0; }
    button.search-submit {
      position: absolute;
      top: 2px;
      right: 2px;
      bottom: 2px;
      svg {
        font-size: 30px; } } } }
