@import '../assets/styles/media-queries.scss';

.Sidebar {
  padding: 0;
  width: 100%;
  @include gt-xs {
    margin-left: 6%;
    width: 32%; }
  .sidebar-widget {
    margin-bottom: 40px;
    .widget-title {
      font-size: 12px;
      letter-spacing: 0.14em;
      margin-top: 0;
      margin-bottom: 2em;
      text-transform: uppercase; }
    .widget-content {} } }
