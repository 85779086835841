@import '../assets/styles/media-queries.scss';

.Post {
  padding-bottom: 2rem;
  .entry-header {
    margin: 1rem 0 1.5rem;
    .entry-title {
      font-weight: 300;
      margin-top: 1rem;
      margin-bottom: 0; } } }
