@import '../assets/styles/media-queries.scss';

.BottomBar {
  border-top: 1px solid #eee;
  display: flex;
  flex-direction: column;
  .socials {
    padding: 2em 3rem 1.5rem;
    .social {
      background-color: #767676;
      border-radius: 48px;
      color: #fff;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 48px;
      width: 48px;
      &:hover,
      &:active {
        background-color: #333;
        box-shadow: none; }
      svg {
        font-size: 24px; } } } }
